
<template>
    <div class="workbench">
      <img src="../../assets/image/数据内容空 1.png" alt="" />
      <div class="workbench_trips">
        <span>该功能正在开发中...敬请期待</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'cockpit',
    data() {
      return {}
    },
    created() {
     
    },
    methods: {}
  }
  </script>
  
  <style lang="less" scoped>
  .workbench {
    width: 100%;
    height: 100%;
    background: #fff;
    img {
      width: 303px;
      height: 303px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .workbench_trips {
      position: absolute;
  
      color: #999999;
      font-size: 18px;
      top: 67%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  </style>